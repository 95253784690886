@import "global";

.study-card {
  $this: &;
  &__image {
    @include position(absolute, 0);
    z-index: -1;

    img {
      @include size(100%);
      object-fit: cover;
      object-position: center center;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    min-height: 550px;
    z-index: 2;
    width: 100%;
  }
  &__content {
    padding: 90px 60px 40px 60px;
    min-height: 100%;
    background-color: $cPrimary;
    color: $cWhite;
    opacity: 0;
    width: 100%;
    transition: opacity .4s;
    @include ie-va-fix();
    //TODO Vyresit IE min-Height neroztahuje podle velikosti IMG
    &-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      p {
        font-weight: $fwLight;
      }
    }
    &-title {
      margin-bottom: 15px;
    }
    &:hover {
      opacity: 1;
    }
  }

  &__bottom {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    margin-top: spacer(context);
    &:nth-of-type(odd) {
      text-align: right;
    }
    #{$this}__cta {
      display: inline-flex;
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__cta {
    min-width: 187px;
    margin-left: 20px;
  }
  &__tag {
    padding: 10px 28px;
    @extend %typo-b2;
    text-decoration: none;
    border: 1px solid $cWhite;
    transition: color .4s, background-color .4s;
    white-space: nowrap;
    @include margin(10px 20px null null);
    &:hover {
      background-color: $cWhite;
      color: $cPrimary;
    }
    &s {
      display: flex;
      padding-top: 30px;
      margin: auto 0 0 0;
      flex-wrap: wrap;
    }
  }

  &--main,
  &--medium {
    #{$this}__bottom {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
    }
  }

  &--main {

    #{$this}__content {
      width: 100%;
      &-title {
        //@extend %typo-d1;
      }
      &-inner {
        max-width: 60rem;
      }
    }
    #{$this}__inner {
      @include visual-angle('top right',120px, 120px, $cWhite, false);
      min-height: 510px;
    }
  }

  &--medium {
    #{$this}__content {
    }
    #{$this}__inner {
      min-height: 650px;
    }
  }
  @include breakpoint(tablet-landscape down) {
    &__content {
      display: none;
    }
    &__bottom {
      flex-direction: column;
      text-align: left;
    }
    &__cta {
      margin-left: 0;
    }
    &__title {
      text-align: left;
    }
    &--right,
    &--medium {
      #{$this}__bottom {
        flex-direction: column;
      }
      #{$this}__cta {
        margin-left: 0;
      }
      #{$this}__title {
        text-align: left;
      }
    }
    &--medium {
      #{$this}__inner {
        min-height: 550px;
      }
    }

  }
  @include breakpoint(phone down) {
    &__content {
      padding: 30px;
    }
    &--main,
    &--medium {
      #{$this}__bottom {
        flex-direction: column;
      }
    }
    &__title {
      margin-bottom: 12px;
    }
    &--main {

      #{$this}__inner {
        @include visual-angle('top right',80px, 80px, $cWhite, false);
      }
    }
  }
}
